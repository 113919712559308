import React from 'react';
import Game from '../Game';

function App() {
  return (
    <div>
      <main>
        <Game />
      </main>
    </div>
  );
}

export default App;
